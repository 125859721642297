<template>
  <div class="coupon-list">
    <div class="tab-hold">
      <div class="tab-second flex">
        <van-tabs v-model:active="active" @change="tabChange">
          <van-tab title="全部"></van-tab>
          <van-tab title="即将失效的券"></van-tab>
        </van-tabs>
        <div class="right flex flex-right" @click="toHistory">
          <img src="@/assets/img/user/coupon/history.png" alt="" />
          <p>历史记录</p>
        </div>
      </div>
    </div>
    <div v-if="isFinish" class="main">
      <template v-if="list.length > 0">
        <div v-for="(item, index) in list" :key="index" class="m-li">
          <div class="t-m flex">
            <img src="@/assets/img/user/coupon/tou.png" alt="" />
            <div class="center">
              <p>{{ item.coupon_name || "" }}</p>
              <p>{{ item.goodName || "" }}</p>
            </div>
            <div class="right">
              <div class="right-top flex align-end flex-center">
                <p class="unit">￥</p>
                <p class="r-i">{{ item.face_value }}</p>
              </div>
              <p class="r-b">
                {{
                  item.coupon_qualification === 0
                    ? "无门槛"
                    : Number(item.coupon_qualification || 0) > 0
                    ? "满" + item.coupon_qualification + "可用"
                    : ""
                }}
              </p>
            </div>
          </div>
          <div class="c-m flex">
            <div class="c-l">
              <p class="str">{{ item.coupon_type }}</p>
              <div class="c-b flex">
                <p>有效期至{{ item.valid_end_date }}</p>
                <!-- 快到期先不显示 -->
                <!-- <p>{{item.exr}}</p> -->
              </div>
            </div>
            <p v-if="item.goodId" class="use" @click="goGoodsDetailFunc(item.goodId)">去使用</p>
          </div>
        </div>
        <p class="bottom">——  已经到底啦  ——</p>
      </template>
      <template v-else>
        <div class="list-no">
          <img src="@/assets/img/user/coupon/no-data.png" alt="" />
          <p>暂无优惠券</p>
        </div>
      </template>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.coupon-list {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #f5f5f5;
  .tab-hold {
    position: fixed;
    width: 100%;
    height: 52px;
    background: #ffffff;
    -webkit-border-radius: 0px 0px 10px 10px;
    border-radius: 0px 0px 10px 10px;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 10;
    .van-tabs,
    .van-tabs__wrap {
      height: 100%;
    }
    .van-tabs__nav--line {
      padding-bottom: 0 !important;
      margin: 0 auto;
    }
    .van-tabs__line {
      width: 28px !important;
      height: 2px !important;
      background: #e62129 !important;
      bottom: 0 !important;
      -webkit-border-radius: 2px !important;
      border-radius: 2px !important;
    }
    .van-tab {
      height: 21px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500 !important;
      color: #999999 !important;
      line-height: 21px !important;
      padding: 0 !important;
      flex: unset !important;
    }
    .van-tab:nth-child(1) {
      width: auto !important;
      margin: 16px 30px 0 5px !important;
    }
    .van-tab:nth-child(2) {
      width: auto !important;
      margin: 16px 0 0 0 !important;
    }
    .van-tab--active {
      color: #e62129 !important;
    }
  }
}
</style>
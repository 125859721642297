import {
    queryCouponList
} from '@/utils/coupon.js';
export default {
    name: "CouponList",
    data() {
        return {
            active: 0,
            list: [],
            isFinish: false
        }
    },
    created() {
        this.getCoupon(1, 3)
    },
    methods: {
        tabChange() {
            if (this.active == 1) {
                this.getCoupon(2, 3)
            } else {
                this.getCoupon(1, 3)
            }
        },
        getCoupon(sort, status) {
            this.isFinish = false
            let data = {
                memberId: this.$cookies.get('memberId'),
                sortMethod: sort,
                status: status
            };
            queryCouponList(data).then(res => {
                if (res && res.code == "0") {
                    this.list = res.data.couponUser_list || []
                }
                this.isFinish = true
            })
        },
        toHistory() {
            this.$router.push("/couponHistory")
        },
        // 跳转商品详情
        goGoodsDetailFunc(goodsId) {
            if (!goodsId) {
                return
            }
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    goodsId: goodsId
                }
            })
        }
    }
}